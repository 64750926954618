import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import './about.css'
import Seo from '../components/Seo/seo';
import { Layout } from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import ImageText from '../components/ImageText/ImageTextView';
import { getImageByRelativePath } from '../shared/utilities';
import useWindowDimensions from '../hooks/useWindowDimensions';



const AboutPage: FC = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'aboutusPage' });

    const {width} = useWindowDimensions();
    function getBanner(){
        var banner 
        if (width > 768) {
            banner = <StaticImage
            className={'banner'}
                src="../images/banner6.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
    
        }
        else{
            banner = <StaticImage
            className={'banner'}
                src="../images/banner6_mobile.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
        }
        return banner
    }
    const banner = getBanner();

    const data: any = useStaticQuery(graphql`
    {
        Test: file(relativePath: {eq: "felice.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 600, height: 700)
         }
         relativePath
       }
         Test2: file(relativePath: {eq: "waldemar.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test3: file(relativePath: {eq: "boot.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test4: file(relativePath: {eq: "coffee.jpeg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test5: file(relativePath: {eq: "family.jpeg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
       Test6: file(relativePath: {eq: "FOTO5.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 900)
        }
        relativePath
      }
     
     }
    `);

    return (
        <Layout>
            <Seo title={"Over ons"} description={"Galapagos is de plek die wij - Waldemar en Felice - thuis noemen."} />

            {banner}

            <h3>“Nature is not a place to visit, it is our home”</h3>

            <ImageText hasButton={[false, "", ""]} image={getImageByRelativePath("felice.jpg", data)} header_text={t('image_textblock1_header')} image_left={true} text={t('image_textblock1_content1') + '\n\n' + t('image_textblock1_content2') } />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ImageText hasButton={[false, "", ""]} image={getImageByRelativePath("waldemar.jpg", data)} header_text={t('image_textblock2_header')} image_left={false} text={t('image_textblock2_content1') + '\n\n' + t('image_textblock2_content2') } />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ImageText hasButton={[false, "", ""]} image={getImageByRelativePath("boot.jpg", data)} header_text={t('image_textblock3_header')} image_left={true} text={t('image_textblock3_content')} />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ImageText hasButton={[false, "", ""]} image={getImageByRelativePath("coffee.jpeg", data)} header_text={t('image_textblock4_header')} image_left={false} text={t('image_textblock4_content')} />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ImageText hasButton={[false, "", ""]} image={getImageByRelativePath("FOTO5.jpg", data)} header_text={t('image_textblock5_header')} image_left={true} text={t('image_textblock5_content')} />
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div style={{
                textAlign: 'center',
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '1.5rem 0 1.5rem 0',
                boxShadow: '0 0 10px rgb(72 72 72 / 16%)',
                zIndex: '200',
                backgroundColor: '#ffffff',
                marginBottom: '50px'
            }}>

                <div className='contact_link'>
                    <div style={{ paddingBottom: '30px' }} >{t('footerText')}</div>
                    <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/contact/'>{t('aboutButton')}</Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage;

